import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { auth_login, wallet_set, bot_setting_set } from "./store";
import * as api from "./api";
import "./resource/styles/global.css";
import "antd/dist/antd.css";
import Lottie from "react-lottie";
import Loading from "./lotties/main_loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Login = lazy(() => import("./pages/login"));
const Main = lazy(() => import("./pages/main"));
const Home = lazy(() => import("./pages/home"));
const Setting = lazy(() => import("./pages/setting"));
const Play = lazy(() => import("./pages/play"));
const User = lazy(() => import("./pages/user"));
const Promotion = lazy(() => import("./pages/promotion"));
const HistoryPage = lazy(() => import("./pages/history"));
const MaintenanceYehyeh = lazy(() => import("./pages/maintenance"));
const MaintenanceIbot = lazy(() => import("./pages/maintenance-ibot"));
const Test = lazy(() => import("./pages/test"));
const Product = lazy(() => import("./pages/product"));
const CardGame = lazy(() => import("./pages/cardGame"));
const Steelball = lazy(() => import("./pages/steelball"));
const DragonTigger = lazy(() => import("./pages/dragontigger"));
const Article = lazy(() => import("./pages/article"));

function App() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const botSetting = useSelector((state) => state.botSetting);
  const auth = useSelector((state) => state.auth);
  const [maintenence, setMaintenence] = useState(false);
  const [maintenenceYehyeh, setMaintenenceYehyeh] = useState(false);
  const [maintenenceIbot, setMaintenenceIbot] = useState(false);
  const [host, setHost] = useState("");
  useEffect(() => {
    const url = window.location;
    init();
    if (url !== url.host) {
      setHost(url.host);
    }
  }, []);

  async function init() {
    try {
      let res = await api.maintenence();
      if (res) {
        Cookies.set(
          "maintenence",
          JSON.stringify(
            {
              bac: res.data.m.bac,
              rot: res.data.m.rot,
              dt: res.data.m.dt,
            },
            { expires: 10080 }
          )
        );
        setMaintenence(res.data.m.maintenance);
        setMaintenenceIbot(res.data.m.maintenance);
        setMaintenenceYehyeh(false);
        if (res.data.status == 503) {
          setMaintenence(true);
          setMaintenenceYehyeh(true);
          setMaintenenceIbot(false);
        }
      }
      const auth = Cookies.get("auth");
      if (auth) {
        const data = JSON.parse(auth);
        dispatch(
          auth_login({
            bot_id: data.bot_id,
            id: data.id,
            username: data.username,
            trial: data.trial,
          })
        );

        const walletRes = await api.getWallet(data.id);
        const userBotRes = await api.getUsetBot(data.id);
        if (walletRes.data.success) {
          dispatch(wallet_set(walletRes.data.data));
          dispatch(
            bot_setting_set({
              ...botSetting,
              init_wallet: walletRes.data.data.all_wallet,
            })
          );
        } else {
          history.push("/login");
        }

        if (userBotRes.data.success && userBotRes.data.data.bot) {
          dispatch(
            bot_setting_set({
              ...userBotRes.data.data.bot,
            })
          );
          history.push(
            `/${validateBotType(userBotRes.data.data.bot.botObj.bot_type)}/play`
          );
          return;
        } else {
          if (window.location.pathname !== "/history") {
            history.push("/home");
          }
        }
      } else {
        // history.push("/home");
      }
    } catch (error) {
      console.log("App | error while call init()", error);
    }
  }

  function validateBotType(type) {
    console.log(type);
    switch (type) {
      case 3:
        return "dragon-tiger";
      case 2:
        return "roulette";
      case 210:
        return "roulette";
      case 220:
        return "roulette";
      case 230:
        return "roulette";
      case 240:
        return "roulette";
      case 250:
        return "roulette";
      default:
        return "baccarat";
    }
  }
  const admin = ["ibotxthai"];

  return (
    <Suspense
      fallback={
        <Lottie
          options={defaultOptions}
          height={100}
          width={100}
          style={{
            // width: "100%",
            // height: "100%",
            minWidth: "100vw",
            minHeight: "100vh",
            backgroundImage: "url('/newBG1.webp')",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
          }}
        />
      }
    >
      {maintenence &&
      !admin.includes(auth.username) &&
      host !== "localhost:3000" ? (
        <Switch>
          {maintenenceIbot && <MaintenanceIbot />}
          {maintenenceYehyeh && <MaintenanceYehyeh />}
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/test">
            <Test />
          </Route>
          <Route exact path="/article">
            <Article />
          </Route>
          <Route exact path="/steelball">
            <Steelball />
          </Route>
          <Route exact path="/dragontigger">
            <DragonTigger />
          </Route>
          <Route exact path="/cardGame">
            <CardGame />
          </Route>
          <Route exact path="/product">
            <Product />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <Main />
          </Route>
          <Route exact path="/home">
            <Main />
          </Route>
          <Route exact path="/:game/setting">
            <Setting />
          </Route>
          <Route exact path="/:game/play">
            <Play />
          </Route>
          <Route exact path="/user">
            <User />
          </Route>
          <Route exact path="/promotion">
            <Promotion />
          </Route>
          <Route exact path="/history">
            <HistoryPage />
          </Route>
        </Switch>
      )}
    </Suspense>
  );
}

export default App;
